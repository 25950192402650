.cover {
  padding: 0px 30px;
  position: relative;
}

.left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.scroll-images {
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
}

.child img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.scroll-images::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
}

.scroll-images::-webkit-scrollbar-thumb {
  background-color: black;
}

.child {
  min-width: 120px;
  height: 100px;
  margin: 1px 10px;
  overflow: hidden;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}